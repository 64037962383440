<template>
	<div class="modal-content">
		<div class="subscribed">
			<img
				src="../../assets/img/landing/role/pic__subscribed--x2.png"
				alt="Иконка"
				class="subscribed__pic"
			>
			<div class="title-block">
				СПАСИБО ЗА ПОМОЩЬ!
			</div>
			<div
				class="button orange lg"
				@click.stop.prevent="openUrl"
			>
				Отлично!
			</div>
		</div>
	</div>
</template>

<script>
export default {
    computed: {
        payload() {
            return this.$store.state.modal.payload
        }
    },
    methods: {
        openUrl () {
            window.location.href = this.payload
        }
    }
}
</script>
