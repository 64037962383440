<template>
	<section
		id="chooseToy"
		class="section section-toys"
	>
		<div class="content">
			<div class="toys">
				<div class="tree">
					<img
						src="../assets/img/christmas/pic__tree.png"
						alt="Ёлка"
						class="tree__pic"
					>
					<div class="tree__toys">
						<div
							v-for="(toy, index) in toysOnWoods"
							:key="index"
							class="tree-toy"
							:class="[
								`tree-toy--${index + 1}`,
								toy.active ? 'tree-toy--selected' : '',
							]"
						>
							<img
								:src="toy.img"
								alt=""
								class="tree-toy__pic"
							>
						</div>
					</div>
				</div>
				<div class="toys__content">
					<h3 class="title-section">
						Выбрать новогоднюю игрушку на ёлке
					</h3>
					<div class="toys__list">
						<div
							v-for="(toy, index) in toys"
							:key="index"
							:class="[
								'toy',
								toy.active ? 'toy--selected' : '',
							]"
							@click="setToySum(toy.sum, index)"
						>
							<img
								:src="toy.img"
								alt="Игрушка"
								class="toy__pic"
							>
						</div>
					</div>
					<div v-if="!isCustomSum">
						<div
							class="toys__price"
						>
							{{ lessonText.sum }} ₽
						</div>
						<p class="toys__text">
							{{ lessonText.text }}
						</p>
					</div>
					<h3
						id="customSum"
						class="title-section"
					>
						Или ввести другую сумму
					</h3>
					<div class="toys__sum">
						<div class="field-simple">
							<span class="field-simple__title">Введите сумму</span>
							<input
								ref="inputCustomSum"
								v-model="$v.customSum.$model"
								type="number"
								class="field-simple__value"
								:class="{'field-simple__value--error': $v.customSum.$error}"
								placeholder="сумма"
								@focus="setToySum(1, 3)"
							>
							<div
								v-if="$v.customSum.$error"
								class="field-simple__error"
							>
								{{
									!$v.customSum.required
										? "Это обязательное поле"
										: !$v.customSum.maxValue || !$v.customSum.minValue
											? "недопустимая сумма"
											: "Ошибка поля"
								}}
							</div>
						</div>
						<button
							class="button button--red"
							@click="$scrollTo('#applicationForm')"
						>
							Исполнить желание
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { required, minLength, maxValue, minValue } from 'vuelidate/lib/validators';
import { onlyNumber } from '@/assets/scripts/custom_validators';

export default {
    name: 'Toys',
    data: () => ({
        customSum: '',
        isCustomSum: false,
        sumToy: 730,
        activeToy: 1,
        isShowMaxSum: false,
        lessonText: {
            img: require('../assets/img/christmas/pic__toy-1.png'),
            sum: 730,
            text:
                '1 индивидуальное занятие с преподавателем по выбранному предмету для воспитанника социального учреждения.',
            active: true
        },
        toys: [
            {
                img: require('../assets/img/christmas/pic__toy-1.png'),
                sum: 730,
                text:
                    '1 индивидуальное занятие с преподавателем по выбранному предмету для воспитанника социального учреждения.',
                active: true
            },
            {
                img: require('../assets/img/christmas/pic__toy-2.png'),
                sum: 1460,
                text:
                    '2 индивидуальных занятия с преподавателем по выбранному предмету для воспитанника социального учреждения.',
                active: false
            },
            {
                img: require('../assets/img/christmas/pic__toy-3.png'),
                sum: 5840,
                text:
                    '8 индивидуальных занятий с преподавателем по выбранному предмету для воспитанника социального учреждения.\n',
                active: false
            },
            {
                img: require('../assets/img/christmas/pic__toy-4.png'),
                sum: 5840,
                text: '',
                active: false
            }
        ],
        toysOnWoods: [
            {
                img: require('../assets/img/christmas/pic__toy-1--tree.png'),
                active: true,
                light: 1
            },
            {
                img: require('../assets/img/christmas/pic__toy-2--tree.png'),
                active: false,
                light: 2
            },
            {
                img: require('../assets/img/christmas/pic__toy-3--tree.png'),
                active: false,
                light: 3
            },
            {
                img: require('../assets/img/christmas/pic__toy-4--tree.png'),
                active: false,
                light: 4
            }
        ]
    }),
    validations: {
        customSum: {
            required,
            onlyNumber,
            minLength: minLength(1),
            minValue: minValue(1),
            maxValue: maxValue(1000000)
        },
    },
    watch: {
        customSum() {
            if (this.customSum > 0) {
                this.isShowMaxSum = true
                this.isCustomSum = true
                this.toysOnWoods.map((el) => {
                    el.active = false;
                    if (el.light === 4) {
                        this.activeToy = 4
                        el.active = true
                    }
                })
                this.$emit('getSumToy', Number(this.customSum))
            }
        },
    },
    methods: {
        setToySum(sum, i) {
            this.$v.$reset()
            this.toys.map((el, index) => el.active = index === i)
            if (i === 3) {
                this.isShowMaxSum = true
                this.$refs.inputCustomSum.focus()
                this.toysOnWoods.map((el, index) => el.active = index === i)
                this.activeToy = 4
                this.isCustomSum = true
                this.$emit('getSumToy', this.customSum)
            } else {
                this.isShowMaxSum = false
                this.lessonText = this.toys.find((el) => el.active === true)
                this.isCustomSum = false
                this.customSum = ''
                this.sumToy = sum
                this.toysOnWoods.map((el, index) => el.active = index === i)
                this.activeToy = i + 1
                this.$emit('getSumToy', this.sumToy)
            }
        }
    }
};
</script>
