<template>
	<div class="modal-content">
		<div class="center">
			<img
				src="../../assets/img/icons/icon-alert-error.svg"
				class="alert-icon"
			>
			<h3
				class="modal-title"
			>
				Ошибка
			</h3>
			<br>
			<div class="text dark">
				<p>{{ payload }}</p>
			</div>
			<div
				class="button orange lg"
			>
                Закрыть
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload'
        })
    }
}
</script>
