<template>
	<section class="section section-helpers">
		<div class="content">
			<div class="helpers">
				<h3 class="title-section">
					Уже помогли
				</h3>
				<div class="helpers__list">
					<div
						v-for="item in shownHelpedList"
						:key="item.id"
						class="helper"
					>
						<div class="helper__name">
							{{ item.name_value }} {{ item.company }}
						</div>
						<div class="helper__sum">
							{{
								item.transaction.amount_rub
									| toPlural('рубль', 'рубля', 'рублей')
							}}
						</div>
					</div>
				</div>
				<div
					v-if="helpers.length > 3"
					style="text-align: center; margin-top: 20px"
					@click="loadMore"
				>
					<button class="button__item">
						{{ buttonName }}
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'Helpers',
    data: () => ({
        isShowHelped: false,
        helpersToShow: 3
    }),
    computed: {
        ...mapGetters('christmas', {helpers: 'helped'}),
        shownHelpedList() {
            return (this.isShowHelped ? this.helpers.slice(0, this.helpersToShow) : this.helpers.slice(0, 3))

        },
        buttonName () {
            if (this.shownHelpedList.length === this.helpers.length) return 'Свернуть'
            return 'Показать еще'
        }
    },
    created() {
        this.getDonations()
    },
    methods: {
        ...mapActions('christmas', {
            getDonations: 'getDonations'
        }),
        loadMore() {
            this.isShowHelped = this.helpersToShow <= this.helpers.length
            this.helpersToShow = this.helpersToShow + 10
        },
    }
};
</script>
