import { helpers } from 'vuelidate/lib/validators';
const onlyNumber = helpers.regex('onlyNumber', /^[1-9]\d*$/)
const alphaSpace = helpers.regex('alphaSpace', /^[a-zA-Zа-яА-ЯёЁ]{1}[\s\-a-zA-Zа-яА-ЯёЁ]*$/)
const Phone = helpers.regex('Phone', /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/)

export {
    onlyNumber,
    alphaSpace,
    Phone
}
