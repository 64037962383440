<template>
    <div
        :class="modalClass"
        @mousedown="clickOutModal"
        @keydown.esc.stop.prevent="modalClose()"
    >
        <div class="modal-container">
            <div class="modal-inner">
                <div
                    class="modal-body"
                >
                    <div
                        class="modal-close"
                        data-modal-close
                        @click.stop="modalClose"
                    />
                    <component :is="currentActive" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'

import StdFail from './StdFail'

import DonateSuccess from './DonateSuccess'
import ShowQR from './ShowQR'


export default {
    components: {
        'stdFail': StdFail,
        'donate-scs': DonateSuccess,
        'show-qr': ShowQR
    },
    computed: {
        ...mapGetters('modal', [
            'currentActive',
            'currentSuccess'
        ]),
        modalClass() {
            if (this.currentActive === 'donate-scs' || this.currentActive === 'show-qr' ) return 'modal modal-subscribed'
            else return 'modal'
        }
    },
    methods: {
        clickOutModal(event) {
            if (!event.target.closest('.modal-body')) {
                this.modalClose()
            }
        }
    }
}
</script>
